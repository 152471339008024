import { getDownloadURL, ref } from '@firebase/storage';
import { Fragment, useEffect, useState } from 'react';
import { storageService } from '../myFireBase';
import useDidMountEffect from '../function/useDidMountEffect';
import PlayerDetailMatch from './PlayerDetailMatch';

const PlayerMatch = ({ playerProfile, matchObj, champIconInfo, itemIconInfo, traitIconInfo }) => {
	const [ detailMatchObj, setDetailMatchObj ] = useState([]);
	const [ isLoaded, setIsLoaded ] = useState(false);
	const [ isOpen, setIsOpen ] = useState(false);

	const [ playerNum, setPlayerNum ] = useState(0);
	const [ playerPlace, setPlayerPlace ] = useState(0);

	const [ champArray, setChampArray ] = useState([]);

	// const [ champImg, setChampImg ] = useState([]);
	// const [ champNewImg, setChampNewImg ] = useState([]);

	// const [ tchampImg, tsetChampImg ] = useState([]);

	const [ champItem, setChampItem ] = useState([]);
	// const [ itemImg, setItemImg ] = useState([]);

	const [ champRare, setChampRare ] = useState([]);
	const [ champTier, setChampTier ] = useState([]);

	const [ totalGold, setTotalGold ] = useState();

	const [ champTrait, setChampTrait ] = useState([]);
	// const [ traitImg, setTraitImg ] = useState([]);
	const [ traitBgImg, setTraitBgImg ] = useState([]);

	const _tierstar = [ '', '◆', '◆◆', '◆◆◆' ];

	const _season = 5;

	useEffect(async () => {
		for (let i = 0; i < 8; i++) {
			const _placeObj = matchObj.participants.find((o) => o.placement === i + 1);
			setDetailMatchObj((old) => [ ...old, _placeObj ]);
		}

		const _playerPlace = matchObj.participants.findIndex((o) => o.puuid === playerProfile.puuid);

		setPlayerNum(_playerPlace);
		setPlayerPlace(matchObj.participants[_playerPlace].placement);

		for (let i = 0; i <= 4; i++) {
			const _ref = ref(storageService, `gs://tft-flat.appspot.com/icon/trait/${i}.png`);
			const _traitImg = await getDownloadURL(_ref);
			setTraitBgImg((old) => ({ ...old, [i]: _traitImg }));
		}

		let _totalGold = 0;
		matchObj.participants[_playerPlace].units.forEach(async (element) => {
			_totalGold += (element.rarity + 1) * Math.pow(3, element.tier - 1);
			setChampArray((old) => [ ...old, element.character_id ]);
			setChampItem((old) => [ ...old, element.items ]);
			setChampRare((old) => [ ...old, element.rarity ]);
			setChampTier((old) => [ ...old, element.tier ]);
		});
		setTotalGold(_totalGold);

		matchObj.participants[_playerPlace].traits.forEach(async (element) => {
			if (element.style !== 0) {
				setChampTrait((old) => [ ...old, element ]);
			}
		});
	}, []);

	// const getBg = async () => {
	// };

	// matchObj.participants[_playerPlace].traits.forEach(async (element) => {
	// 	const _t = element.name.slice(5).toLowerCase();
	// 	const _ref = ref(storageService, `gs://tft-flat.appspot.com/icon/trait/${_season}/edited/${_t}.png`);
	// 	const _traitImg = await getDownloadURL(_ref);
	// 	setTraitImg((old) => ({ ...old, [element.name]: _traitImg }));
	// });

	// matchObj.participants[_playerPlace].units.forEach(async (element) => {
	// 	element.items.forEach(async (e) => {
	// 		const _ref = ref(storageService, `gs://tft-flat.appspot.com/icon/item/${e}.png`);
	// 		const _itemImg = await getDownloadURL(_ref);
	// 		setItemImg((old) => ({ ...old, [e]: _itemImg }));
	// 	});
	// });

	// 	matchObj.participants[_playerPlace].units.forEach(async (element) => {
	// 		const _ref = ref(
	// 			storageService,
	// 			`gs://tft-flat.appspot.com/icon/champion/${_season}/${element.character_id}.png`
	// 		);
	// 		const _champImg = await getDownloadURL(_ref);
	// 		setChampImg((old) => [
	// 			...old,
	// 			{
	// 				name: element.character_id,
	// 				img: _champImg
	// 			}
	// 		]);
	// 		tsetChampImg((old) => ({ ...old, [element.character_id]: _champImg }));
	// 	});
	// }, []);

	// useDidMountEffect(
	// 	() => {
	// 		if (Object.keys(champTrait).length === 5) {
	// 			setTimeout(() => {
	// 				setIsLoaded(true);
	// 			}, 1000);
	// 		}
	// 	},
	// 	[ traitBgImg ]
	// );

	useDidMountEffect(
		() => {
			setTimeout(() => {
				setIsLoaded(true);
			}, 1000);
		},
		[ champArray ]
	);

	// useDidMountEffect(
	// 	() => {
	// 		if (champImg.length >= 8) {
	// 			champArray.forEach(async (element) => {
	// 				const _index = champImg.findIndex((o) => o.name === element);
	// 				const _img = champImg[_index].img;
	// 				setChampNewImg((old) => [ ...old, _img ]);
	// 			});
	// 		}
	// 	},
	// 	[ champImg ]
	// );

	// useDidMountEffect(() => {}, [ champNewImg ]);

	// useDidMountEffect(
	// 	() => {
	// 		setIsLoaded(true);
	// 	},
	// 	[ itemImg ]
	// );
	// matchObj.participants[playerNum].units.length

	const onOpen = () => {
		setIsOpen((old) => !old);
	};

	return (
		<Fragment>
			{isLoaded ? (
				<Fragment>
					<div className={`player-match-frame placement${playerPlace}`}>
						<div className="player-match-frame-head">
							<div className="match-head-info">
								<span id="player-match-place" className={`placeNum${playerPlace}`}>
									{playerPlace}
								</span>
								<span id="player-match-time">
									{Math.floor((matchObj.participants[playerNum].last_round - 4) / 7) + 2}-{Math.floor((matchObj.participants[playerNum].last_round - 4) % 7)}
									{/* {Math.floor(matchObj.game_length / 60)}:{Math.floor(matchObj.game_length % 60)} */}
								</span>
								<span id="player-match-gold">({totalGold}g)</span>
							</div>
							<div className="match-head-trait">
								{champTrait.map(
									(obj, index) =>
										obj.style ? (
											<div
												className="match-trait-bg"
												style={{
													backgroundImage: `url(${traitBgImg[obj.style]})`
												}}
											>
												<img
													className="match-trait-bgImg"
													// src={traitImg[obj.name]}
													src={traitIconInfo[obj.name]}
													width="25px"
													height="25px"
												/>
											</div>
										) : (
											<Fragment />
										)
								)}
							</div>
						</div>
						<div className="player-match-frame-body">
							<div className="match-champ-frame">
								{champArray.map((obj, index) => (
									<div className="match-champ-frame-detail">
										<p className={`match-champ-tier rare-tier${champRare[index]}`}>
											{_tierstar[champTier[index]]}
										</p>
										<img
											className={`match-champ-img rare${champRare[index]}`}
											// src={tchampImg[champArray[index]]}
											src={champIconInfo[champArray[index]]}
											width="40px"
											height="40px"
											// alt={champArray[index]}
										/>
										<div className="match-item-frame">
											{champItem[index].map((obj2, index2) => (
												<img
													className="match-item-img"
													// src={itemImg[champItem[index][index2]]}
													src={itemIconInfo[champItem[index][index2]]}
													width="13px"
													height="13px"
													// alt={champItem[index][index2]}
													// title={champItem[index][index2]}
												/>
											))}
										</div>
									</div>
								))}
							</div>
							<div className="match-below-frame">
								<div />
							</div>
						</div>
						<div className="player-match-frame-foot">
							{isOpen ? (
								<table className="match-detail-table">
									<thead>
										<tr>
											<th>place</th>
											<th>summoner</th>
											<th>round</th>
											<th>trait</th>
											<th>champ</th>
											<th>gold</th>
										</tr>
									</thead>
									<tbody>
										{detailMatchObj.map((element, index) => (
											<PlayerDetailMatch
												detailObj={element}
												place={index}
												playerPlace={playerPlace}
												champIconInfo={champIconInfo}
												itemIconInfo={itemIconInfo}
												traitIconInfo={traitIconInfo}
											/>
										))}
									</tbody>
								</table>
							) : (
								<div />
							)}
						</div>
					</div>

					<button className="match-open-button" onClick={onOpen} />
				</Fragment>
			) : (
				<div />
			)}
		</Fragment>
	);
};

export default PlayerMatch;
