import { getDownloadURL, ref } from '@firebase/storage';
import axios from 'axios';
// import jsonAdapter from 'axios-jsonp';
// import axios from 'axios-jsonp-pro';
import { Fragment, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import ChampionDesc from '../component/ChampionDesc';
import ChampionNav from '../component/ChampionNav';
import ChampionNavigation from '../component/ChampionNavigation';
import { storageService } from '../myFireBase';
import useDidMountEffect from '../function/useDidMountEffect';
import ChampionItemTier from './ChampionItemTier';
import ChampionItem3Tier from './ChampionItem3Tier';

const _season = 5;

const Champion = () => {
	const [ newChampion, setNewChampion ] = useState('');
	const [ isLoaded, setIsLoaded ] = useState(false);

	const [ rawObj, setRawObj ] = useState([]);
	const [ itemObj, setItemObj ] = useState([]);
	const [ championObj, setChampionObj ] = useState([]);
	const [ traitObj, setTraitObj ] = useState([]);

	const [ championIcon, setChampionIcon ] = useState('');
	const [ championCost, setChampionCost ] = useState();
	const [ championName, setChampionName ] = useState('');
	const [ championTrait, setChampionTrait ] = useState([]);
	const [ championStat, setChampionStat ] = useState([]);
	const [ championAbil, setChampionAbil ] = useState([]);

	const history = useHistory();
	const _path = history.location.pathname.split('/');

	let savedLanguage = localStorage.getItem('language');

	useEffect(() => {
		setNewChampion(_path[2]);
	}, []);

	useDidMountEffect(
		() => {
			getRawObj();
			// getChampionObj();
		},
		[ newChampion ]
	);

	const getRawObj = async () => {
		const _lang = savedLanguage.toLowerCase();
		const _rawObj = await axios.get(`https://raw.communitydragon.org/latest/cdragon/tft/${_lang}.json`);
		setRawObj(_rawObj.data);
	};

	useDidMountEffect(
		() => {
			getItemObj();
			getChampionObj();
			getTraitObj();
		},
		[ rawObj ]
	);

	const getItemObj = () => {
		console.log(rawObj.items);
		setItemObj(rawObj.items);
	};

	const getChampionObj = () => {
		console.log(rawObj.sets[_season].champions);
		setChampionObj(rawObj.sets[_season].champions);
	};

	const getTraitObj = () => {
		console.log(rawObj.sets[_season].traits);
		setTraitObj(rawObj.sets[_season].traits);
	};

	let _pos;
	useDidMountEffect(
		() => {
			Object.entries(championObj).forEach(([ key, value ]) => {
				if (value.apiName === newChampion) {
					_pos = key;
				}
			});
			getChampionIcon();
			getChampionCost();
			getChampionName();
			getChampionTrait();
			getChampionStat();
			getChampionAbil();
		},
		[ championObj ]
	);

	const getChampionIcon = async () => {
		const _ref = ref(storageService, `gs://tft-flat.appspot.com/icon/champion/${_season}/${newChampion}.png`);
		const _championIcon = await getDownloadURL(_ref);
		// console.log('02', _championIcon);
		setChampionIcon(_championIcon);
	};

	const getChampionCost = () => {
		// const _pos = championObj.indexOf({ newChampion });
		setChampionCost(championObj[_pos].cost);
	};

	const getChampionName = () => {
		setChampionName(championObj[_pos].name);
	};

	const getChampionTrait = () => {
		setChampionTrait(championObj[_pos].traits);
	};

	const getChampionStat = () => {
		setChampionStat(championObj[_pos].stats);
		console.log(championObj[_pos].stats);
	};

	const getChampionAbil = () => {
		setChampionAbil(championObj[_pos].ability);
	};

	useDidMountEffect(
		() => {
			setIsLoaded(true);
		},
		[ championIcon, championCost, championName, championTrait, championStat, championAbil ]
	);

	return (
		<Fragment>
			{isLoaded ? (
				<div id="champion-view" className="view">
					{/* <ChampionNavigation /> */}
					<div id="champion-div">
						{/* <ChampionNav championObj={championObj} /> */}
						<div id="champion-head">
							<div id="champion-head-first">
								<img src={championIcon} width="100px" height="100px" />
								<div id="champion-head-main">
									<p>💰 {championCost}</p>
									<h2>{championName}</h2>
									<p>
										» {championTrait[0]} » {championTrait[1]}
									</p>
									{championTrait.length === 3 ? <p> » {championTrait[2]}</p> : <Fragment />}
								</div>
							</div>
							<div id="champion-head-detail">
								<div id="champion-head-detail-front">
									<h2>{championAbil.name}</h2>
									<h3>
										💧 {championStat.initialMana}/{championStat.mana}
									</h3>
								</div>
								<ChampionDesc desc={championAbil.desc} variables={championAbil.variables} />
							</div>
						</div>
						<div id="champion-body">
							<div>
								<ChampionItemTier newChampion={newChampion} />
								<ChampionItem3Tier newChampion={newChampion} />
							</div>
						</div>
					</div>
				</div>
			) : (
				<div>
					<div />
				</div>
			)}
		</Fragment>
	);
};

export default Champion;
