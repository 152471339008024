import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

const PlayerSearch = () => {
	const [ newPlayer, setNewPlayer ] = useState('');
	const [ newRegion, setNewRegion ] = useState('');

	let savedRegion;

	useEffect(() => {
		savedRegion = localStorage.getItem('Region');
		if (savedRegion !== null) setNewRegion(savedRegion);
		else {
			localStorage.setItem('Region', 'kr');
			setNewRegion('kr');
		}
	}, []);

	const history = useHistory();
	const onSubmit = (event) => {
		event.preventDefault();
		history.push(`/player/${newRegion}/${newPlayer}`);
	};

	const onChange = (event) => {
		const { value } = event.target;
		setNewPlayer(value);
	};

	const onRegionChange = (event) => {
		const { value } = event.target;
		setNewRegion(value);
		localStorage.setItem('Region', value);
	};

	return (
		<section id="player-search-section">
			<div id="player-search-div">
				<form id="player-search-form" onSubmit={onSubmit}>
					<select id="player-search-region" value={newRegion} onChange={onRegionChange}>
						<option value="br2">BR</option>
						<option value="eun1">EUNE</option>
						<option value="euw1">EUW</option>
						<option value="jp1">JP</option>
						<option value="kr">KR</option>
						<option value="la1">LAN</option>
						<option value="la2">LAS</option>
						<option value="na1">NA</option>
						<option value="oc1">OCE</option>
						<option value="tr1">TR</option>
						<option value="ru">RU</option>
					</select>
					<input
						type="text"
						id="player-search-name"
						placeholder="Search Summoner"
						value={newPlayer}
						onChange={onChange}
						required
					/>
					<div id="player-search-box">
						{/* <Link to={{ pathname: `/player/${newRegion}/${newPlayer}` }} id="player-search-button" /> */}
						<input type="submit" id="player-search-button" value="..q" />
					</div>
				</form>
			</div>
		</section>
	);
};

export default PlayerSearch;
