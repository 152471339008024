import axios from 'axios';
import { getDownloadURL, ref } from '@firebase/storage';
import { dbService, storageService } from '../myFireBase';
import { doc, getDoc } from '@firebase/firestore';

const funcInfoObj = async (info_type, info_attr) => {
	const _season = await getDoc(doc(dbService, `/api/argument`));
	const SEASON = _season.data().season;

	let rawObj;

	let itemObj = new Array();
	let championObj = new Array();
	let traitObj = new Array();

	let itemJson = new Object();
	let championJson = new Object();
	let traitJson = new Object();

	let savedLanguage = localStorage.getItem('language');

	const getItemIcon = async () => {
		itemObj.forEach(async (element) => {
			const _ref = ref(storageService, `gs://tft-flat.appspot.com/icon/item/${element.id}.png`);
			const _icon = await getDownloadURL(_ref);
			itemJson[element.id] = _icon;
		});
		return itemJson;
	};
	const getItemName = async () => {
		itemObj.forEach(async (element) => {
			itemJson[element.id] = element.name;
		});
		return itemJson;
	};

	const getChampionIcon = async () => {
		championObj.forEach(async (element) => {
			const _ref = ref(
				storageService,
				`gs://tft-flat.appspot.com/icon/champion/${SEASON}/${element.apiName}.png`
			);
			const _icon = await getDownloadURL(_ref);
			championJson[element.apiName] = _icon;
		});
		return championJson;
	};
	const getChampionCost = async () => {
		championObj.forEach(async (element) => {
			championJson[element.apiName] = element.cost;
		});
		return championJson;
	};
	const getChampionName = async () => {
		championObj.forEach(async (element) => {
			championJson[element.apiName] = element.name;
		});
		return championJson;
	};
	const getChampionTrait = async () => {
		championObj.forEach(async (element) => {
			championJson[element.apiName] = element.trait;
		});
		return championJson;
	};
	const getChampionStat = async () => {
		championObj.forEach(async (element) => {
			championJson[element.apiName] = element.stats;
		});
		return championJson;
	};
	const getChampionAbil = async () => {
		championObj.forEach(async (element) => {
			championJson[element.apiName] = element.ability;
		});
		return championJson;
	};

	const getTraitIcon = async () => {
		traitObj.forEach(async (element) => {
			const _t = element.apiName.slice(5).toLowerCase();
			const _ref = ref(storageService, `gs://tft-flat.appspot.com/icon/trait/${SEASON}/edited/${_t}.png`);
			const _icon = await getDownloadURL(_ref);
			traitJson[element.apiName] = _icon;
		});
		return traitJson;
	};
	const getTraitName = async () => {
		traitObj.forEach(async (element) => {
			traitJson[element.apiName] = element.name;
		});
		return traitJson;
	};
	const getTraitStyle = async () => {
		for (let i = 0; i <= 4; i++) {
			const _ref = ref(storageService, `gs://tft-flat.appspot.com/icon/trait/${i}.png`);
			const _icon = await getDownloadURL(_ref);
			traitJson[i] = _icon;
		}
		return traitJson;
	};

	const getItemObj = async () => {
		console.log('++++++', rawObj);
		rawObj.items.forEach((element) => {
			if (
				(0 < element.id && element.id < 100) ||
				(2011 <= element.id && element.id < 2100) ||
				(1111 <= element.id && element.id <= 1197)
			) {
				itemObj.push(element);
			}
		});
		// itemObj = rawObj.items;
		switch (info_attr) {
			case 'icon':
				return await getItemIcon();
				break;
			case 'name':
				return await getItemName();
				break;
			// getItemEffect();
		}
	};

	const getChampionObj = async () => {
		championObj = rawObj.sets[SEASON].champions;
		switch (info_attr) {
			case 'icon':
				return await getChampionIcon();
				break;
			case 'cost':
				return await getChampionCost();
				break;
			case 'name':
				return await getChampionName();
				break;
			case 'trait':
				return await getChampionTrait();
				break;
			case 'stat':
				return await getChampionStat();
				break;
			case 'abil':
				return await getChampionAbil();
				break;
		}
	};

	const getTraitObj = async () => {
		traitObj = rawObj.sets[SEASON].traits;
		switch (info_attr) {
			case 'icon':
				return await getTraitIcon();
				break;
			case 'name':
				return await getTraitName();
				break;
			case 'style':
				return await getTraitStyle();
				break;
			// case 'abil':
			// 	getTraitAbil();
			// 	break;
		}
	};

	const getRawObj = async () => {
		const _lang = savedLanguage.toLowerCase();
		const _rawObj = await axios.get(`https://raw.communitydragon.org/latest/cdragon/tft/${_lang}.json`);
		rawObj = _rawObj.data;

		switch (info_type) {
			case 'item':
				return await getItemObj();
				break;
			case 'champ':
				return await getChampionObj();
				break;
			case 'trait':
				return await getTraitObj();
				break;
		}
	};

	return await getRawObj();
};

export default funcInfoObj;
