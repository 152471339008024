import { doc, setDoc } from '@firebase/firestore';
import axios from 'axios';
import { Fragment, useEffect, useState } from 'react';
import PlayerSearch from '../component/PlayerSearch';
import { dbService } from '../myFireBase';
import useDidMountEffect from '../function/useDidMountEffect';

// const RIOT_API = 'RGAPI-664ec0a9-b88b-4343-b6f0-e01801222377';

const Home = () => {
	// const _continent = 'asia';
	// const _id = 'KR_5414772173';

	// const [ matchObj, setMatchObj ] = useState('[]');

	// useEffect(() => {
	// 	updateMatch(_id, _continent);
	// }, []);

	// const updateMatch = async (_id, _continent) => {
	// 	const _temp = await axios.get(
	// 		'https://kr.api.riotgames.com/tft/league/v1/master?api_key=RGAPI-f5ca2e86-606c-4f24-97be-a2311a8d89df'
	// 	);
	// 	console.log(_temp);

	// 	const _matchObj = await axios.get(`https:/${_continent}.api.riotgames.com/tft/match/v1/matches/${_id}?api_key=${RIOT_API}
	// 	`);
	// 	const { info } = _matchObj.data;
	// 	console.log(info);
	// 	await setDoc(doc(dbService, `/match/${info.tft_set_number}/${_continent}/${_id}`), {
	// 		game_datetime: info.game_datetime,
	// 		participants: info.participants
	// 	});
	// 	setMatchObj(info);
	// };

	// useDidMountEffect(
	// 	() => {
	// 		// updateStat();
	// 	},
	// 	[ matchObj ]
	// );

	return (
		<Fragment>
			<PlayerSearch />
			<div />
		</Fragment>
	);
};

export default Home;
