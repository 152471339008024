import { collection, getDocs, orderBy, query } from '@firebase/firestore';
import { getDownloadURL, ref } from '@firebase/storage';
import { Fragment, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { dbService, storageService } from '../myFireBase';
import useDidMountEffect from '../function/useDidMountEffect';
import funcInfoObj from '../function/funcInfoObj';

const ItemTier = () => {
	const history = useHistory();
	const _season = 5;

	const [ isLoaded, setIsLoaded ] = useState(false);

	const [ tierObj, setTierObj ] = useState([]);
	const [ tierObjSize, setTierObjSize ] = useState();
	const [ tierObjFixed, setTierObjFixed ] = useState([]);

	const [ itemIcon, setItemIcon ] = useState([]);

	const [ iconInfo, setIconInfo ] = useState([]);
	const [ nameInfo, setNameInfo ] = useState([]);

	// const [ rawObj, setRawObj ] = useState([]);
	// const [ champObj, setChampObj ] = useState([]);
	// const [ champName, setChampName ] = useState([]);

	const date = new Date(Date.now());
	// ${date.getUTCDate()}

	useEffect(async () => {
		await getIconInfo();
		await getNameInfo();
		getTierObj();
	}, []);

	const getIconInfo = async () => {
		const _icon = await funcInfoObj('item', 'icon');
		setIconInfo(_icon);
	};
	const getNameInfo = async () => {
		const _name = await funcInfoObj('item', 'name');
		setNameInfo(_name);
	};

	const getTierObj = async () => {
		const _query = query(collection(dbService, `stat/11.19/item`), orderBy('totaluse', 'desc'));
		const _tierObj = await getDocs(_query);
		setTierObjSize(_tierObj.size);
		_tierObj.forEach((document) => {
			const _tierObj2 = {
				...document.data(),
				id: document.id
			};
			setTierObj((old) => [ ...old, _tierObj2 ]);
		});
	};

	useDidMountEffect(
		() => {
			if (tierObj.length === tierObjSize) fixTierObj();
		},
		[ tierObj ]
	);

	const fixTierObj = () => {
		for (let i = 1; i < tierObj.length; i++) {
			let _average = 0;
			for (let j = 1; j <= 8; j++) {
				if (!isNaN(tierObj[i][j])) _average += tierObj[i][j] * j;
			}
			_average /= tierObj[i].totaluse;

			let _toprate = 0;
			for (let j = 1; j <= 4; j++) {
				if (!isNaN(tierObj[i][j])) _toprate += tierObj[i][j];
			}

			const _tierObj = {
				usage: (tierObj[i].totaluse + 1) / (tierObj[0].totaluse + 1),
				winrate: tierObj[i][1] ? tierObj[i][1] / tierObj[i].totaluse : 0,
				averge: _average,
				toprate: _toprate / tierObj[i].totaluse,
				id: tierObj[i].id
			};
			setTierObjFixed((old) => [ ...old, _tierObj ]);
		}
	};

	useDidMountEffect(
		() => {
			// tierObjFixed.forEach(async (element) => {
			// 	const _ref = ref(storageService, `gs://tft-flat.appspot.com/icon/item/${element.id}.png`);
			// 	const _itemIcon = await getDownloadURL(_ref);
			// 	setItemIcon((old) => ({ ...old, [element.id]: _itemIcon }));
			// });
			setTimeout(() => {
				setIsLoaded(true);
			}, 1000);
			// setIsLoaded(true);
		},
		[ iconInfo ]
	);

	const onClick = (event) => {
		// console.log(event);
		// if (event.target.className === 'tier-list-item') history.push(`../item/${event.target.title}`);
		// else {
		// 	history.push(`../champion/${event.target.parentElement.title}`);
		// }
		// window.location.reload();
	};

	return (
		<Fragment>
			{isLoaded ? (
				<div className="view">
					<div className="tier-detail-view">
						<div className="tier-head">
							<div className="tier-list-item tier-head-item">
								<span />
								<span />
								<span />
								<span>Usage%</span>
								<span>Winrate%</span>
								<span>Avg.place</span>
								<span>Top 4%</span>
							</div>
						</div>
						<div className="tier-list">
							{tierObjFixed.map((_item, _index) => (
								<div className="tier-list-item" title={_item.id} onClick={onClick}>
									<span> {_index + 1} </span>
									<img src={iconInfo[_item.id]} width="40px" height="40px" />
									<span> {nameInfo[_item.id]} </span>
									<span> {parseFloat((_item.usage * 100).toFixed(2))}% </span>
									<span> {parseFloat((_item.winrate * 100).toFixed(2))}% </span>
									<span> {parseFloat(_item.averge).toFixed(2)} </span>
									<span> {parseFloat((_item.toprate * 100).toFixed(2))}% </span>
								</div>
							))}
						</div>
					</div>
				</div>
			) : (
				<div />
			)}
		</Fragment>
	);
};

export default ItemTier;
