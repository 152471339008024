import { useState } from 'react';
import './App.css';
import AppRouter from './component/AppRouter';

function App() {
	// const [ isPlayerIn, setIsPlayerIn ] = useState(false);
	// const [ playerObj, setPlayerObj ] = useState(null);
	return (
		<div className="App">
			<header className="App-header" />
			<AppRouter />
			<footer className="App-footer">
				This stie isn’t endorsed by Riot Games and doesn’t reflect the views or opinions of Riot Games or anyone
				officially involved in producing or managing League of Legends. League of Legends and Riot Games are
				trademarks or registered trademarks of Riot Games, Inc. League of Legends © Riot Games, Inc. @4
			</footer>
		</div>
	);
}

export default App;
