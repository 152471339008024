// Import the functions you need from the SDKs you need
import { getFunctions } from '@firebase/functions';
import { initializeApp } from 'firebase/app';
// import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
	apiKey: 'AIzaSyCplmM_Bc1jxksAelJspc5Gmbr34ExjPcE',
	authDomain: 'tft-flat.firebaseapp.com',
	projectId: 'tft-flat',
	storageBucket: 'tft-flat.appspot.com',
	messagingSenderId: '952895637528',
	appId: '1:952895637528:web:2da4d52627dd8c4b345b6d',
	measurementId: 'G-YR66H6ZMZY'
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

export const dbService = getFirestore();
export const storageService = getStorage(app, 'gs://tft-flat.appspot.com/');
export const functionService = getFunctions(app, 'asia-northeast3');
