import { getDownloadURL, ref } from '@firebase/storage';
import { Fragment, useEffect, useState } from 'react';
import useDidMountEffect from '../function/useDidMountEffect';
import { storageService } from '../myFireBase';

const PlayerDetailMatch = ({ detailObj, place, playerPlace, champIconInfo, itemIconInfo, traitIconInfo }) => {
	const [ isLoaded, setIsLoaded ] = useState(false);
	const [ isOpen, setIsOpen ] = useState(false);
	const [ isPlayer, setIsPlayer ] = useState(false);

	const [ champArray, setChampArray ] = useState([]);

	const [ champItem, setChampItem ] = useState([]);

	const [ champRare, setChampRare ] = useState([]);
	const [ champTier, setChampTier ] = useState([]);

	const [ totalGold, setTotalGold ] = useState();

	const [ champTrait, setChampTrait ] = useState([]);
	const [ traitBgImg, setTraitBgImg ] = useState([]);

	const _tierstar = [ '', '◆', '◆◆', '◆◆◆' ];

	const _season = 5;

	useEffect(async () => {
		console.log('1. ', detailObj);
		if (place === playerPlace - 1) setIsPlayer(true);

		for (let i = 0; i <= 4; i++) {
			const _ref = ref(storageService, `gs://tft-flat.appspot.com/icon/trait/${i}.png`);
			const _traitImg = await getDownloadURL(_ref);
			setTraitBgImg((old) => ({ ...old, [i]: _traitImg }));
		}

		let _totalGold = 0;
		detailObj.units.forEach(async (element) => {
			_totalGold += (element.rarity + 1) * Math.pow(3, element.tier - 1);
			setChampArray((old) => [ ...old, element.character_id ]);
			setChampItem((old) => [ ...old, element.items ]);
			setChampRare((old) => [ ...old, element.rarity ]);
			setChampTier((old) => [ ...old, element.tier ]);
		});
		setTotalGold(_totalGold);

		detailObj.traits.forEach(async (element) => {
			if (element.style !== 0) {
				setChampTrait((old) => [ ...old, element ]);
			}
		});
	}, []);

	useDidMountEffect(
		() => {
			setTimeout(() => {
				setIsLoaded(true);
			}, 1000);
		},
		[ champArray ]
	);

	return (
		<tr className={`is${isPlayer}`}>
			{isLoaded ? (
				<Fragment>
					<td className="placement">{place + 1}</td>
					<td className="summoner">누누와 케넨</td>
					<td className="round">
						{Math.floor((detailObj.last_round - 4) / 7) + 2}-{Math.floor((detailObj.last_round - 4) % 7)}
					</td>
					<td rowSpan="1" className="traits">
						<div className="traits-container">
							{champTrait.map(
								(obj, index) =>
									obj.style ? (
										<div
											className="bg"
											style={{
												backgroundImage: `url(${traitBgImg[obj.style]})`
											}}
										>
											<img
												className="bgImg"
												src={traitIconInfo[obj.name]}
												width="20px"
												height="20px"
											/>
										</div>
									) : (
										<Fragment />
									)
							)}
						</div>
					</td>
					<td rowSpan="1" className="champions">
						<div className="champions-container">
							{champArray.map((obj, index) => (
								<div className="match-champ-frame-detail">
									<p className={`match-champ-tier rare-tier${champRare[index]}`}>
										{_tierstar[champTier[index]]}
									</p>
									<img
										className={`match-champ-img rare${champRare[index]}`}
										src={champIconInfo[champArray[index]]}
										width="30px"
										height="30px"
										// alt={champArray[index]}
									/>
									<div className="item-frame">
										{champItem[index].map((obj2, index2) => (
											<img
												className="match-item-img"
												src={itemIconInfo[champItem[index][index2]]}
												width="10px"
												height="10px"
												// alt={champItem[index][index2]}
											/>
										))}
									</div>
								</div>
							))}
						</div>
					</td>
					<td className="gold">({totalGold}g)</td>
				</Fragment>
			) : (
				<Fragment />
			)}
		</tr>
	);
};
{
	/* <div className={`player-match-frame detail-frame placement${place + 1}`}>
					<div className="player-match-frame-head detail-frame">
						<div className="match-head-info">
							<span id="player-match-place" className={`placeNum${place + 1}`}>
								{place + 1}
							</span>
							<span id="player-match-time">
								{Math.floor((detailObj.last_round - 4) / 7) + 2}-{Math.floor((detailObj.last_round - 4) % 7)}
							</span>
							<span id="player-match-gold">({totalGold}g)</span>
						</div>
						<div className="match-head-trait">
							{champTrait.map(
								(obj, index) =>
									obj.style ? (
										<div
											className="match-trait-bg"
											style={{
												backgroundImage: `url(${traitBgImg[obj.style]})`
											}}
										>
											<img
												className="match-trait-bgImg"
												// src={traitImg[obj.name]}
												src={traitIconInfo[obj.name]}
												width="25px"
												height="25px"
											/>
										</div>
									) : (
										<Fragment />
									)
							)}
						</div>
					</div>
					<div className="player-match-frame-body detail-frame">
						<div className="match-champ-frame detail-frame">
							{champArray.map((obj, index) => (
								<div className="match-champ-frame-detail">
									<p className={`match-champ-tier rare-tier${champRare[index]}`}>
										{_tierstar[champTier[index]]}
									</p>
									<img
										className={`match-champ-img rare${champRare[index]}`}
										// src={tchampImg[champArray[index]]}
										src={champIconInfo[champArray[index]]}
										width="40px"
										height="40px"
										// alt={champArray[index]}
									/>
									<div className="match-item-frame">
										{champItem[index].map((obj2, index2) => (
											<img
												className="match-item-img"
												// src={itemImg[champItem[index][index2]]}
												src={itemIconInfo[champItem[index][index2]]}
												width="13px"
												height="13px"
												// alt={champItem[index][index2]}
												// title={champItem[index][index2]}
											/>
										))}
									</div>
								</div>
							))}
						</div>
						<div className="match-below-frame">
							<div />
						</div>
					</div>
				</div>
			</Fragment>
			) : (
			<div />
			)}
		</Fragment>
	); */
}

export default PlayerDetailMatch;
