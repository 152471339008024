import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import Home from '../route/Home';
import Champion from '../route/Champion';
import Item from '../route/Item';
import Player from '../route/Player';
import Navigation from './Navigation';
import Trait from '../route/Trait';
import Comp from '../route/Comp';
import ChampionTier from '../route/ChampionTier';
import ItemTier from '../route/ItemTier';
import TraitTier from '../route/TraitTier';

const AppRouter = () => {
	return (
		<Router>
			<Navigation />
			<Route path="/" exact>
				<Home />
			</Route>
			<Route path="/champion" exact>
				<ChampionTier />
			</Route>
			<Route path="/champion/:id" exact>
				<Champion />
			</Route>
			<Route path="/item" exact>
				<ItemTier />
			</Route>
			<Route path="/item/:id" exact>
				<Item />
			</Route>
			<Route path="/comp" exact>
				<Comp />
			</Route>
			<Route path="/trait" exact>
				<TraitTier />
			</Route>
			<Route path="/trait/:id" exact>
				<Trait />
			</Route>
			<Route path="/player/:region?/:id?" exact>
				<Player />
			</Route>
		</Router>
	);
};

export default AppRouter;
