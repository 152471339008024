import axios from 'axios';
import { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import useDidMountEffect from '../function/useDidMountEffect';

const Navigation = () => {
	const [ languageObj, setLanguageObj ] = useState(null);
	const [ language, setLanguage ] = useState('');
	const [ isLoaded, setIsLoaded ] = useState(false);
	const [ isToggleMenu, setIsToggleMenu ] = useState(false);

	let savedLanguage;

	useEffect(() => {
		getLanguageObj();
		savedLanguage = localStorage.getItem('language');
		if (savedLanguage !== null) setLanguage(savedLanguage);
		else {
			localStorage.setItem('language', 'en_US');
			setLanguage('en_US');
		}
	}, []);

	const getLanguageObj = async () => {
		const _languageObj = await axios.get('https://ddragon.leagueoflegends.com/cdn/languages.json');
		setLanguageObj(_languageObj.data);
	};

	useDidMountEffect(
		() => {
			setIsLoaded(true);
		},
		[ languageObj ]
	);

	const onLanguageChange = (event) => {
		const { value } = event.target;
		setLanguage(value);
		localStorage.setItem('language', value);
		window.location.reload();
	};

	useDidMountEffect(() => {}, [ language ]);

	const onToggleMenu = (event) => {
		setIsToggleMenu((prev) => !prev);
	};

	const onToggleMenuFalse = (event) => {
		setIsToggleMenu(false);
	};

	return (
		<nav className="App-nav">
			<div className="App-nav-div">
				<ul className="App-nav-menu">
					<button className="App-nav-button" onClick={onToggleMenu}>
						≡
					</button>
					<li>
						<NavLink
							to="/"
							className="App-nav-item"
							activeClassName="selected"
							onClick={onToggleMenuFalse}
							exact
						>
							Home
						</NavLink>
					</li>
					<li className="App-nav-menu-item">
						<NavLink to="/champion" className="App-nav-item" activeClassName="selected">
							Champion
						</NavLink>
					</li>
					<li className="App-nav-menu-item">
						<NavLink to="/item" className="App-nav-item" activeClassName="selected">
							Item
						</NavLink>
					</li>
					<li className="App-nav-menu-item">
						<NavLink to="/trait" className="App-nav-item" activeClassName="selected">
							Trait
						</NavLink>
					</li>
					<li className="App-nav-menu-item">
						<NavLink to="/comp" className="App-nav-item" activeClassName="selected">
							Comp
						</NavLink>
					</li>
					{/* <li>
						<a id="App-support-link" href="https://www.naver.com" className="App-nav-item">
							Support
						</a>
					</li> */}
					{isLoaded ? (
						<li id="App-nav-right">
							<span id="App-nav-ico">🌍</span>
							<select
								id="App-nav-lang"
								className="App-nav-item"
								value={language}
								onChange={onLanguageChange}
							>
								{languageObj.map((_lang) => <option value={_lang}>{_lang}</option>)}
							</select>
						</li>
					) : (
						<div />
					)}
				</ul>
			</div>
			{isToggleMenu ? (
				<ul className="App-nav-menu-mobile">
					<li className="App-nav-menu-item-mobile">
						<NavLink to="/champion" className="App-nav-item" onClick={onToggleMenu}>
							Champion
						</NavLink>
					</li>
					<li className="App-nav-menu-item-mobile">
						<NavLink to="/item" className="App-nav-item" onClick={onToggleMenu}>
							Item
						</NavLink>
					</li>
					<li className="App-nav-menu-item-mobile">
						<NavLink to="/trait" className="App-nav-item" onClick={onToggleMenu}>
							Trait
						</NavLink>
					</li>
					<li className="App-nav-menu-item-mobile">
						<NavLink to="/comp" className="App-nav-item" onClick={onToggleMenu}>
							Comp
						</NavLink>
					</li>
				</ul>
			) : (
				<div />
			)}
		</nav>
	);
};

export default Navigation;
