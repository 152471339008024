import { useEffect, useRef, useState } from 'react';

const useDidMountEffect = (func, deps) => {
	const _didMount = useRef(false);

	useEffect(() => {
		if (_didMount.current) func();
		else _didMount.current = true;
	}, deps);
};

export default useDidMountEffect;
