import { Fragment } from 'react';

const ChampionDesc = ({ desc, variables }) => {
	let decoratedDesc = desc;

	for (let i = 0; i < variables.length; i++) {
		let _pattern = variables[i].name;
		let _regexAllCase = new RegExp(`\@[a-zA-Z]*${_pattern}[^@]*\@[a-zA-Z]*`, 'gi');
		decoratedDesc = decoratedDesc.replaceAll(
			_regexAllCase,
			`[${parseFloat(variables[i].value[1].toFixed(2))}/${parseFloat(
				variables[i].value[2].toFixed(2)
			)}/${parseFloat(variables[i].value[3].toFixed(2))}[`
		);
	}

	decoratedDesc = decoratedDesc.replaceAll(/<br>/g, '\n');
	// console.log(decoratedDesc);

	decoratedDesc = decoratedDesc.replaceAll(/\([^)]*\)/g, '');

	const _re = new RegExp('/ [ | ] /');
	let splitDesc = decoratedDesc.split('[');
	console.log(splitDesc);

	return (
		<Fragment>
			<p className="desc-split">{splitDesc.map((text, index) => <span>{text}</span>)}</p>
		</Fragment>
	);
};

export default ChampionDesc;
