import { collection, getDocs, limit, orderBy, query, where } from '@firebase/firestore';
import { getDownloadURL, ref } from '@firebase/storage';
import { Fragment, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { dbService, storageService } from '../myFireBase';
import useDidMountEffect from '../function/useDidMountEffect';
import useInfoObj from '../function/useInfoObj';
import funcInfoObj from '../function/funcInfoObj';

const ChampionItem3Tier = ({ newChampion }) => {
	const history = useHistory();
	const _season = 5;

	const [ isLoaded, setIsLoaded ] = useState(false);

	const [ tierObj, setTierObj ] = useState([]);
	const [ tierObjSize, setTierObjSize ] = useState();
	const [ tierObjFixed, setTierObjFixed ] = useState([]);

	const [ champIcon, setChampIcon ] = useState([]);

	const [ iconInfo, setIconInfo ] = useState([]);
	const [ nameInfo, setNameInfo ] = useState([]);

	// const [ rawObj, setRawObj ] = useState([]);
	// const [ champObj, setChampObj ] = useState([]);
	// const [ champName, setChampName ] = useState([]);

	const date = new Date(Date.now());
	// ${date.getUTCDate()}

	useEffect(async () => {
		await getIconInfo();
		await getNameInfo();
		getTierObj();
	}, []);

	const getIconInfo = async () => {
		const _icon = await funcInfoObj('item', 'icon');
		setIconInfo(_icon);
	};
	const getNameInfo = async () => {
		const _name = await funcInfoObj('item', 'name');
		setNameInfo(_name);
	};

	const getTierObj = async () => {
		const _query = query(
			collection(dbService, `stat/11.19/champion/${newChampion}/item3`),
			orderBy('totaluse', 'desc'),
			limit(10)
		);
		const _tierObj = await getDocs(_query);
		setTierObjSize(_tierObj.size);
		_tierObj.forEach((document) => {
			let splitObj = document.id.split('+');
			const _tierObj2 = {
				...document.data(),
				id: { ...splitObj }
			};
			setTierObj((old) => [ ...old, _tierObj2 ]);
		});
	};

	useDidMountEffect(
		() => {
			console.log('%%%', tierObj);
			if (tierObj.length === tierObjSize) fixTierObj();
		},
		[ tierObj ]
	);

	const fixTierObj = () => {
		let all = 0;
		for (let i = 0; i < tierObj.length; i++) all += tierObj[i].totaluse;

		for (let i = 0; i < tierObj.length; i++) {
			let _average = 0;
			for (let j = 1; j <= 8; j++) {
				if (!isNaN(tierObj[i][j])) _average += tierObj[i][j] * j;
			}
			_average /= tierObj[i].totaluse;

			let _toprate = 0;
			for (let j = 1; j <= 4; j++) {
				if (!isNaN(tierObj[i][j])) _toprate += tierObj[i][j];
			}

			const _tierObj = {
				usage: (tierObj[i].totaluse + 1) / (all + 1),
				winrate: tierObj[i][1] ? tierObj[i][1] / tierObj[i].totaluse : 0,
				averge: _average,
				toprate: _toprate / tierObj[i].totaluse,
				id: tierObj[i].id
			};
			setTierObjFixed((old) => [ ...old, _tierObj ]);
		}
	};

	useDidMountEffect(
		() => {
			setTimeout(() => {
				setIsLoaded(true);
			}, 1000);
		},
		[ iconInfo ]
	);

	return (
		<Fragment>
			{isLoaded ? (
				<div className="view">
					<div className="tier-detail-view">
						<div className="tier-head">
							<div className="tier-list-item tier-head-item">
								<span />
								<span />
								<span />
								<span>Usage%</span>
								<span>Winrate%</span>
								<span>Avg.place</span>
								<span>Top 4%</span>
							</div>
						</div>
						<div className="tier-list tier-list-5">
							{tierObjFixed.map((_item, _index) => (
								<div className="tier-list-item" title={_item.id}>
									<span> {_index + 1} </span>
									<img src={iconInfo[_item.id[0]]} width="40px" height="40px" />
									<img src={iconInfo[_item.id[1]]} width="40px" height="40px" />
									<img src={iconInfo[_item.id[2]]} width="40px" height="40px" />
									<p class="tier-item-3"> </p>
									<span> {parseFloat((_item.usage * 100).toFixed(2))}% </span>
									<span> {parseFloat((_item.winrate * 100).toFixed(2))}% </span>
									<span> {parseFloat(_item.averge).toFixed(2)} </span>
									<span> {parseFloat((_item.toprate * 100).toFixed(2))}% </span>
								</div>
							))}
						</div>
					</div>
				</div>
			) : (
				<div />
			)}
		</Fragment>
	);
};

export default ChampionItem3Tier;
