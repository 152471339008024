import { Fragment, useEffect, useState } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router';
import useDidMountEffect from '../function/useDidMountEffect';
import { getDownloadURL, ref } from '@firebase/storage';
import { storageService } from '../myFireBase';

const Item = () => {
	// const [ newItem, setNewItem ] = useState('');
	// const [ isLoaded, setIsLoaded ] = useState(false);
	// const [ enObj, setEnObj ] = useState([]);
	// const [ rawObj, setRawObj ] = useState([]);
	// const [ itemObj, setItemObj ] = useState();
	// const [ itemIcon, setItemIcon ] = useState();
	// const [ itemName, setItemName ] = useState();
	// const [ itemDesc, setItemDesc ] = useState();
	// const [ itemEffect, setItemEffect ] = useState();
	// const [ itemFrom, setItemFrom ] = useState();
	// const history = useHistory();
	// const _path = history.location.pathname.split('/');
	// let savedLanguage = localStorage.getItem('language');
	// useEffect(() => {
	// 	setNewItem(parseInt(_path[2]));
	// }, []);
	// useDidMountEffect(
	// 	() => {
	// 		getRawObj();
	// 	},
	// 	[ newItem ]
	// );
	// const getRawObj = async () => {
	// 	const _lang = savedLanguage.toLowerCase();
	// 	const _rawObj = await axios.get(`https://raw.communitydragon.org/latest/cdragon/tft/${_lang}.json`);
	// 	setRawObj(_rawObj.data);
	// };
	// useDidMountEffect(
	// 	() => {
	// 		getItemObj();
	// 	},
	// 	[ rawObj ]
	// );
	// const getItemObj = () => {
	// 	setItemObj(rawObj.items);
	// };
	// let _pos;
	// useDidMountEffect(
	// 	() => {
	// 		console.log(itemObj);
	// 		_pos = itemObj.findIndex((o) => o.id === newItem);
	// 		// Object.entries(itemObj).some(([ key, value ]) => {
	// 		// 	if (value.id === newItem) {
	// 		// 		_pos = key;
	// 		// 	}
	// 		// });
	// 		getItemIcon();
	// 		getItemName();
	// 		getItemDesc();
	// 		getItemEffect();
	// 		getItemFrom();
	// 	},
	// 	[ itemObj ]
	// );
	// const getItemIcon = async () => {
	// 	const _ref = ref(storageService, `gs://tft-flat.appspot.com/icon/item/${newItem}.png`);
	// 	const _itemIcon = await getDownloadURL(_ref);
	// 	setItemIcon(_itemIcon);
	// };
	// const getItemName = () => {
	// 	setItemName(itemObj[_pos].name);
	// };
	// const getItemDesc = () => {
	// 	setItemDesc(itemObj[_pos].desc);
	// };
	// const getItemEffect = () => {
	// 	setItemEffect(itemObj[_pos].effect);
	// };
	// const getItemFrom = () => {
	// 	setItemFrom(itemObj[_pos].from);
	// };
	// useDidMountEffect(
	// 	() => {
	// 		setIsLoaded(true);
	// 	},
	// 	[ itemName, itemDesc, itemEffect, itemFrom ]
	// );
	// return (
	// 	<Fragment>
	// 		{isLoaded ? (
	// 			<div id="item-view" className="view">
	// 				<div id="item-div">
	// 					<div id="item-head">
	// 						<img src={itemIcon} width="100px" height="100px" />
	// 					</div>
	// 					<div id="item-body">{/* <ItemDesc dsec={item} */}</div>
	// 				</div>
	// 			</div>
	// 		) : (
	// 			<div />
	// 		)}
	// 	</Fragment>
	// );
};

export default Item;
