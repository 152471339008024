import { Fragment, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import axios from 'axios';
import { getDownloadURL, ref } from '@firebase/storage';
import { dbService, functionService, storageService } from '../myFireBase';
import useDidMountEffect from '../function/useDidMountEffect';
import { doc, getDoc, setDoc } from '@firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import PlayerMatch from '../component/PlayerMatch';
import funcInfoObj from '../function/funcInfoObj';

const Player = () => {
	const [ SEASON, setSEASON ] = useState();
	const [ newPlayer, setNewPlayer ] = useState('');
	const [ newRegion, setNewRegion ] = useState('');
	const [ newContinent, setNewContinent ] = useState('');
	const [ isLoaded, setIsLoaded ] = useState(false);

	const [ isRefresh, setIsRefresh ] = useState(true);
	const [ playerObj, setPlayerObj ] = useState(null);
	const [ playerProfile, setPlayerProfile ] = useState(null);
	const [ playerTier, setPlayerTier ] = useState(null);
	const [ playerTierIcon, setPlayerTierIcon ] = useState('');

	const [ isError, setIsError ] = useState(0);

	const [ playerMatch, setPlayerMatch ] = useState([]);
	const [ matchObj, setMatchObj ] = useState([]);

	const [ champIconInfo, setChampIconInfo ] = useState([]);
	const [ traitIconInfo, setTraitIconInfo ] = useState([]);
	const [ itemIconInfo, setItemIconInfo ] = useState([]);

	const history = useHistory();
	const _path = history.location.pathname.split('/');

	// let RIOT_API = 'RGAPI-664ec0a9-b88b-4343-b6f0-e01801222377';

	useEffect(async () => {
		// const _api = await getDoc(doc(dbService, `/api/api`));
		// RIOT_API = _api.data().api;

		const _season = await getDoc(doc(dbService, `/api/argument`));
		setSEASON(_season.data().season);
		setNewRegion(_path[2]);
		setNewPlayer(_path[3]);
		await getChampIconInfo();
		await getItemIconInfo();
		await getTraitIconInfo();
	}, []);

	const getChampIconInfo = async () => {
		const _icon = await funcInfoObj('champ', 'icon');
		setChampIconInfo(_icon);
	};
	const getItemIconInfo = async () => {
		const _icon = await funcInfoObj('item', 'icon');
		setItemIconInfo(_icon);
	};
	const getTraitIconInfo = async () => {
		const _icon = await funcInfoObj('trait', 'icon');
		setTraitIconInfo(_icon);
	};

	useDidMountEffect(
		() => {
			switch (newRegion) {
				case 'na1':
				case 'br1':
				case 'la1':
				case 'la2':
				case 'oc1':
					setNewContinent('americas');
					break;

				case 'kr':
				case 'jp1':
					setNewContinent('asia');
					break;

				case 'eun1':
				case 'enw1':
				case 'tr1':
				case 'ru':
					setNewContinent('europe');
					break;
			}
			getPlayerObj();
		},
		[ newRegion, newPlayer ]
	);

	const getPlayerObj = async () => {
		console.log('111');
		// const _playerProfile = await axios.get(
		// 	`https://${newRegion}.api.riotgames.com/tft/summoner/v1/summoners/by-name/${newPlayer}?api_key=${RIOT_API}`
		// );
		// console.log('1. ', _playerProfile.data);
		// setPlayerProfile(_playerProfile.data);
		// const _playerTier = await axios.get(
		// 	`https://${newRegion}.api.riotgames.com/tft/league/v1/entries/by-summoner/${_playerProfile.data
		// 		.id}?api_key=${RIOT_API}`
		// );
		// setPlayerTier(_playerTier.data[0]);
		// const _playerMatch = await axios.get(
		// 	`https://${newContinent}.api.riotgames.com/tft/match/v1/matches/by-puuid/${_playerProfile.data
		// 		.puuid}/ids?count=${Math.min(
		// 		20,
		// 		_playerTier.data[0].wins + _playerTier.data[0].losses
		// 	)}&api_key=${RIOT_API}`
		// );
		// setPlayerMatch(_playerMatch.data);

		// console.log(_temp);
		const callPlayerObj = httpsCallable(functionService, 'callPlayerObj');
		const _playerObj = await callPlayerObj({ region: newRegion, player: newPlayer });
		console.log('1. ', _playerObj.data);
		setPlayerProfile(_playerObj.data.profile);
		setPlayerTier(_playerObj.data.tier);
		setPlayerMatch(_playerObj.data.match);
		setIsError(_playerObj.data.error);
	};

	// const getPlayerObj = async () => {
	// 	const _playerObj = await axios.get(
	// 		`https://${newRegion}.api.riotgames.com/tft/summoner/v1/summoners/by-name/${newPlayer}?api_key=${RIOT_API}`
	// 	);
	// 	setPlayerObj(_playerObj.data);
	// };

	// useDidMountEffect(
	// 	() => {
	// 		getPlayerTier();
	// 	},
	// 	[ playerObj ]
	// );

	// const getPlayerTier = async () => {
	// 	const _playerTier = await axios.get(
	// 		`https://${newRegion}.api.riotgames.com/tft/league/v1/entries/by-summoner/${playerObj.id}?api_key=${RIOT_API}`
	// 	);
	// 	setPlayerTier(_playerTier.data[0]);
	// };

	// useDidMountEffect(
	// 	async () => {
	// 		await setDoc(
	// 			doc(dbService, `/player/${playerObj.puuid}`),
	// 			{
	// 				name: playerObj.name,
	// 				id: playerObj.id,
	// 				profileIcon: playerObj.profileIconId,
	// 				region: newRegion,
	// 				continent: newContinent,

	// 				tier: playerTier.tier,
	// 				rank: playerTier.rank,
	// 				leaguePoints: playerTier.leaguePoints,
	// 				wins: playerTier.wins,
	// 				losses: playerTier.losses
	// 			},
	// 			{ merge: true }
	// 		);
	// 		getPlayerTierIcon();
	// 	},
	// 	[ playerTier ]
	// );

	const getPlayerTierIcon = async () => {
		const _ref = ref(storageService, `gs://tft-flat.appspot.com/icon/tier/Emblem_${playerTier.tier}.png`);
		const _playerTierIcon = await getDownloadURL(_ref);
		setPlayerTierIcon(_playerTierIcon);
	};

	useDidMountEffect(
		() => {
			getPlayerTierIcon();
		},
		[ playerTier ]
	);

	useDidMountEffect(
		() => {
			getMatchObj();
		},
		[ playerTierIcon ]
	);

	// const getPlayerMatch = async () => {
	// 	const _playerMatch = await axios
	// 		.get(
	// 			`https://${newContinent}.api.riotgames.com/tft/match/v1/matches/by-puuid/${playerObj.puuid}/ids?count=${Math.min(
	// 				8,
	// 				playerTier.wins + playerTier.losses
	// 			)}&api_key=${RIOT_API}`
	// 		)
	// 		.catch((error) => {
	// 			console.log('!!!', error.config);
	// 		});

	// 	setPlayerMatch(_playerMatch.data);
	// const _temp = await getDoc(doc(dbService, `/player/${playerObj.puuid}/match/match`));
	// if (Object.keys(_temp.data()).length !== 0) {
	// 	setPlayerTier(_temp.data().match);
	// } else {
	// 	setIsRefresh(true);
	// 	getPlayerMatch(true);
	// }
	// };

	// useDidMountEffect(
	// 	() => {
	// 		// updateMatch();
	// 		// getMatchObj();
	// 	},
	// 	[ playerMatch ]
	// );

	// const updateMatch = async () => {
	// 	for (let i = 0; i < Math.min(20, playerMatch.length); i++) {

	// 		const _doc = await getDoc(doc(dbService, `/match/5/${newContinent}/${playerMatch[i]}`));
	// 		if (!_doc.exists()) {
	// 			await setDoc(doc(dbService, `/match/5/${newContinent}/${playerMatch[i]}`), {
	// 				set: false
	// 			});
	// 		}
	// 	}

	// 	getMatchObj();=

	const getMatchObj = async () => {
		for (let i = 0; i < playerMatch.length; i++) {
			// const _matchObj = await axios.get(
			// 	`https://${newContinent}.api.riotgames.com/tft/match/v1/matches/${playerMatch[i]}?api_key=${RIOT_API}`
			// );

			// setMatchObj((oldObj) => [ ...oldObj, _matchObj.data.info ]);

			const _doc = await getDoc(doc(dbService, `/match/${SEASON}/${newContinent}/${playerMatch[i]}`));

			if (_doc.exists() && _doc.data().set === true) {
				setMatchObj((oldObj) => [ ...oldObj, _doc.data().info ]);
			}
		}
	};

	useDidMountEffect(
		() => {
			if (matchObj.length === Math.min(20, playerTier.wins + playerTier.losses)) {
				console.log('3. ', matchObj);
				setTimeout(() => {
					setIsLoaded(true);
				}, 1000);
			}
		},
		[ matchObj ]
	);

	return (
		<Fragment>
			<div id="player-view" className="view">
				{isLoaded ? (
					<div id="player-div">
						<div id="player-head">
							<img
								src={`http://ddragon.leagueoflegends.com/cdn/11.18.1/img/profileicon/${playerProfile.profileIconId}.png`}
								width="80px"
								height="80px"
							/>
							<h2>{playerProfile.name}</h2>
							{/* <p>{playerObj.summonerLevel}</p> */}
						</div>
						<div id="player-body">
							<section id="player-tier-section">
								<div id="player-tier-head">
									<img src={playerTierIcon} width="100px" height="100px" />
									<div id="player-tier-head-detail">
										<p>RANK</p>
										<p>
											{playerTier.tier} {playerTier.rank}
										</p>
										<p>▫ {playerTier.leaguePoints} LP</p>
										<p>
											▫ {playerTier.wins} W {playerTier.losses} L
										</p>
									</div>
								</div>
								<div id="player-tier-body" />
							</section>
						</div>
						<div id="player-match">
							<div id="player-match-head" />
							<div id="player-match-body">
								{matchObj.map((obj, index) => (
									<PlayerMatch
										playerProfile={playerProfile}
										matchObj={matchObj[index]}
										champIconInfo={champIconInfo}
										itemIconInfo={itemIconInfo}
										traitIconInfo={traitIconInfo}
									/>
								))}
							</div>
						</div>
					</div>
				) : (
					<p id="load-screen">Loading</p>
				)}
			</div>
		</Fragment>
	);
};

export default Player;
